.top_left_pf_txt {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.stepProgress {
    position: relative;
    padding-left: 24px;
    padding-top: 20px;
    list-style: none;
}
.stepProgress::before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
}
.stepProgress_item {
    position: relative;
    counter-increment: list;
    padding-bottom: 20px;
}

.stepProgress_item strong {
    display: block;
    padding-right: 40px;
    margin-right: 24px;
    margin-bottom: 0;
}
.stepProgress li p {
    margin-right: 16px;
    font-size: 13px;
    margin-bottom: 0;
}
/* .stepProgress_item::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 5px;
    left: -22px;
    width: 12px;
    height: 12px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
} */
/* .stepProgress_item.is_submit::after {
    content: "";
    font-size: 10px;
    color: #fff;
    text-align: center;
    border: 2px solid rgba(63, 39, 131, 1);
    background-color: rgba(63, 39, 131, 1);
} */
.stepProgress_item.is_pending::after {
    content: "";
    font-size: 10px;
    color: #fff;
    text-align: center;
    border: 2px solid rgb(255 170 95);
    background-color: rgb(255 170 95);
}
.stepProgress_item:last-child::before {
    padding-bottom: 0px;
    border: none;
}
.stepProgress_item label {
    font-weight: 400;
    color: #000;
    margin-bottom: 5px;
}