table.custom-tbl {
    border-collapse: collapse;
    width: 100%;
}

table.custom-tbl thead tr th {
    font-weight: 600;
    font-size: 14px;
    color: #3F2783;
    padding: 10px 0;
    width: 20%;
}

table.custom-tbl>thead>tr>th {
    border-bottom: 1px !important;
}

table.custom-tbl tr th {
    border-color: rgba(63, 39, 131, 0.1) !important;
}

table.custom-tbl td {
    line-height: 28px;
    font-size: 14px;
    color: #3f2783;
}

table.custom-tbl tbody tr td {
    font-size: 14px;
    color: #3f2783;
    padding: 14px 2px;
    border-top: 1px solid rgba(63, 39, 131, 0.1);
}

table.custom-tbl thead tr th.col-img {
    width: 80px;
    padding: 14px 5px;
}

table.custom-tbl thead tr th.col-img,
table.custom-tbl tbody tr td.col-img {
    padding: 14px 8px;
    padding-right: 20px;
    width: 5% !important;
}

.pagination {
    padding: 0.5rem;
}

.cases__list .nav-tabs {
    border-bottom: 0;
}

.cases__list .nav-tabs>li>button {
    line-height: 24px;
    font-weight: 600;
    font-size: 14px;
    height: 48px;
    border-bottom: none;
    position: relative;
    margin-right: 15px;
    border-radius: 20px 20px 0 0;
    cursor: default;
    background-color: #fff;
    border: 2px solid rgba(63, 39, 131, 0.7);
    display: block;
    padding: 10px 15px;
    border-bottom: none !important;
}

.cases__list .nav-tabs .nav-link:focus,
.cases__list .nav-tabs .nav-link:hover {
    border-color: rgba(63, 39, 131, 0.7);
}

.cases__list .nav-tabs>li>button.active {
    border: 2px solid rgba(63, 39, 131, 0.7);
    z-index: 1;
}

.cases__list .tab-content {
    padding: 0px !important;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: -4px;
    margin-bottom: 100px;
}

.cases__list .tab-content>div {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.cases__list .tab-content>div {
    border: 2px solid #3f2783;
    position: relative;
    border: 2px solid #3f2783;
    position: relative;
    min-height: 300px;
}

.cases__list .nav-tabs>li>button.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    left: 0;
    bottom: -6px;
    background: #fff;
}

#case-list-tabs-tab-allcases {
    color: #3f2783;
}

#case-list-tabs-tab-submitted {
    color: #3f2783;
}
#case-list-tabs-tab-discontinued {
    color: #3f2783;
}

#case-list-tabs-tab-hand {
    color: #3f2783;
}

#case-list-tabs-tab-elbow {
    color: #3f2783;
}

#case-list-tabs-tab-hip {
    color: #3f2783;
}

#case-list-tabs-tab-foot {
    color: #3f2783;
}
#case-list-tabs-tab-general {
    color: #3f2783;
}

#case-list-tabs-tab-pending {
    color: #ffa14d;
}

#case-list-tabs-tab-pending.active {
    border: 2px solid #ffa14d;
}

#case-list-tabs-tab-working {
    color: #0e75ff;
}

#case-list-tabs-tab-working.active {
    border: 2px solid #0e75ff;
}

#case-list-tabs-tab-complete {
    color: #1dce00;
}

#case-list-tabs-tab-complete.active {
    border: 2px solid #1dce00;
}

#case-list-tabs-tabpane-allcases.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}
#case-list-tabs-tabpane-general.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-submitted.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}
#case-list-tabs-tabpane-discontinued.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-elbow.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-hip.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-hand.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-foot.active.show {
    border: 2px solid #3f2783;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-pending.active.show {
    border: 2px solid #ffa14d;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-working.active.show {
    border: 2px solid #0e75ff;
    padding: 15px 0;
    position: relative;
}

#case-list-tabs-tabpane-complete.active.show {
    border: 2px solid #1dce00;
    padding: 15px 0;
    position: relative;
}

.panel h4 {
    margin: 10px 0px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    /* margin-left: 20px;
	margin-bottom: 30px; */
}

.cases__list .form-control,
.cases__list .form-select {
    max-width: 260px;
}

.col-status.pending_c {
    color: #ffa14d;
}

.col-status.working_c {
    color: #0e75ff;
}

.col-status.complete_c {
    color: #1dce00;
}

.cases__list .new__note_btn {
    min-height: 40px;
    display: inline-block;
    text-align: center;
    width: 140px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
}

.cases__list .new__note_btn img {
    margin-right: 10px;
}

.close-buttonM {
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    /* Add a margin from the top */
    right: 10px;
    /* Adjust the distance from the right */
    background-color: transparent;
    border: 3px solid black;
    color: black;
    font-size: 32px;
    line-height: 1;
    /* Set line-height to 1 for vertical centering */
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    outline: none;
    padding: 0;
    display: flex;
    /* Use flexbox to center content vertically */
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
}

.close-button:hover {
    background-color: #eee;
    /* Add a background color on hover if desired */
}