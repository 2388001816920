.top__header {
    min-height: 72px;
    max-height: 100px;
    width: 100%;
    position: fixed;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
}
.leftLogo {
    margin: 20px 22px;
    width: 72px;
    display: inline-block;
}
.top__header .head_left_arrows{
    display: inline-block;
    
}
.top__header .nav_inner_right {
    -webkit-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
    margin-bottom: 0;
    /* margin-left: 130px; */
    border: none;
    min-height: 73px;
    border-radius: 0;
}
.right_user_menu{
    margin-right: 24px;
}
.right_user_menu ul{
    margin-left: 0;
    margin-bottom: 0;
}
.right_user_menu ul li{
    list-style-type: none;
}
.right_user_menu ul .use_lett{
    margin-top: 15px;
}
.right_user_menu ul .chat-menu {
    margin-right: 34px;
}
.right_user_menu ul .chat-menu img{
    margin-top: -4px;
}
.sidenav_right {
    height: calc(100% - 72px);
    top: 70px;
    right: 16px;
    position: fixed;
    z-index: 1180 !important;
    background-color: #fff;
    color: #000;
    overflow-x: hidden;
    transition: 1s;
    border-radius: 20px 0px 0px 20px;
    box-shadow: 0px 15px 20px 1px rgb(0 0 0 / 15%);
}
.sidenav_right .rightnavInner {
    padding: 25px 20px;
    width: 400px;
    position: relative;
    height: 100%;
}
.signout_btn {
    position: absolute;
    margin-top: 30px;
    left: inherit;
    bottom: 24px;
    color: #3f2783;
    font-weight: 600;
    cursor: pointer;
}
.signout_btn img{
    width: 24px;
    margin-right: 14px;
}
.sidenav_right .close_btn {
    text-align: right;
    margin-top: -4px;
    padding: 0px 8px 8px 32px;
    text-decoration: none;
    font-size: 16px;
    color: #818181;
    display: block;
    transition: 0.3s;
    cursor: pointer;
}
.sidenav_right .pf_details {
    text-align: center;
}
.sidenav_right label.user-letter.large {
    margin: 20px 0;
}
.sidenav_right .pf_details h4 {
    font-size: 18px;
}
.sidenav_right .pf_details .btn_outline {
    width: 80%;
    margin: 15px 0;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 14px;
}
.dr_edit_pf_popup .modal-dialog {
    max-width: 600px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.dr_edit_pf_popup .pf_details {
    text-align: center;
    padding-top: 10px;
}
.dr_edit_pf_popup .pf_details label.user-letter {
    width: 120px;
    height: 120px;
    line-height: 108px;
    font-size: 72px;
    margin: 0;
    background: #5e60ce;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-weight: 600;
}
.dr_edit_pf_popup .pf_details label.user-letter img {
    width: 116px;
    height: 115px;
    border-radius: 50%;
}
.dr_edit_pf_popup .upload_pf_btn {
    width: 200px;
    display: block;
    margin: 0 auto;
    margin-top: 26px;
    margin-bottom: 10px;
    height: 38px;
}
.dr_edit_pf_popup .remove_pf_txt {
    color: #ff0000;
}
.dr_edit_pf_popup .modal-header {
    border: none;
    padding-bottom: 0;
}
.dr_edit_pf_popup .modal-footer {
    border: none;
    padding-top: 0;
    margin-bottom: 20px;
}
.chat__popup--main .modal-header {
    border-bottom: 2px solid rgba(63, 39, 131, 0.1);
}
.chat__popup--main .chat_body {
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}
.chat__popup--main .chat_body p {
    margin-bottom: 0;
}
.chat__popup--main .chat_body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #fff;
}
.chat__popup--main .chat_body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(63, 39, 131, 0.2);
}
.chat__popup--main textarea {
    resize: none;
}
.chat__popup--main {
    display: none;
    position: fixed;
    bottom: 10px;
    right: 15px;
    z-index: 9;
    overflow: hidden;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
}

.chat__popup--main .circle__btn {
    height: 22px;
    background: transparent;
    color: #ff0000;
    font-weight: 400;
    line-height: 13px;
    border: 2px solid #ff0000;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    top: 1px;
    cursor: pointer;
    margin-left: 10px;
    padding: 3px 10px;
}
.message {
    display: flex;
    width: fit-content;
    max-width: 17rem;
    margin-bottom: 20px;
}

.prof {
    min-width: 35px;
    min-height: 35px;
    margin: auto 10px 0 10px;
    background: #ddb06c;
    color: white;
    font-weight: 600;
    text-align: center;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #3f2783;
    margin-left: 0;
}

.message.mMess .prof {
    margin-left: 15px;
}

.messArea {
    display: grid;
    grid-row-gap: 10px;
    font-size: 12px;
}

#sdate {
    margin-bottom: -6px;
    color: #3f2783;
    font-size: 10px;
}

.message .textM {
    width: fit-content;
    border-radius: 10px;
    padding: 6px 10px;
    font-size: 12px;
    border: 1px solid rgba(63, 39, 131, 0.2);
    box-sizing: border-box;
    color: #3f2783;
}

.mMess {
    margin-left: auto;
    overflow: hidden;
}

.mMess .messArea .textM {
    background: rgba(63, 39, 131, 0.1);
    margin-left: auto;
    transition: 0.6s cubic-bezier(0.07, 0.76, 0.13, 1.02);
}
.chat_main {
    max-width: 400px;
    width: 400px;
    margin: 0 auto;
    background: #ffffff;
    border: 2px solid #3f2783;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 10px 10px;
}

.chat_main .chat_left_img {
    width: 20px;
    margin-right: 8px;
    margin-top: -5px;
}

.chat_main .chat_title {
    font-size: 18px;
    padding-top: 10px;
    margin-top: 16px;
    font-weight: 600;
}

.chat_main .chat_right_img {
    width: 24px;
    text-align: right;
    margin-right: 8px;
}

.chat_main .chat_head {
    padding: 10px 10px;
    border-bottom: 2px solid rgba(63, 39, 131, 0.1);
    margin-bottom: 15px;
    width: 105%;
    margin-left: -10px;
}

.chat_main .reply_border {
    border: 2px solid #3f2783;
    padding-bottom: 6px;
}

.chat_main .format_txt_main .bold_txt_icon {
    width: 10px;
}

.chat_main .format_txt_main .Italic_txt {
    width: 9px;
}

.chat_main .format_txt_main .attach_icon {
    width: 12px;
}

.chat_main .format_txt_main .send_icon {
    width: 14px;
}

.chat_main .reply_border #message {
    padding-bottom: 8px;
    font-size: 12px;
    margin-bottom: 6px;
}
.need_help_popup .modal-dialog {
    width: 400px;
    -webkit-transform: translate3d(100%, 0, 0) !important;
    transform: translate3d(100%, 0, 0) !important;
}
.need_help_popup .need_help_left_img {
    margin-right: 20px;
}
.need_help_popup .start_cht_btm-txt {
    padding-left: 50px;
}
.need_help_popup .chatlnk {
    color: #3f2783;
    cursor: pointer;
}
.need_help_popup .modal-header {
    border: none;
    padding-bottom: 0;
}
.nav_inner_search {
    min-width: 100px;
}
span.head_back_icon {
    cursor: pointer;
}
.switch_btn {
    position: absolute;
    margin-top: 30px;
    right: 15px;
    bottom: 24px;
    color: #3f2783;
    font-weight: 600;
    cursor: pointer;
}
.switch_btn img{
    width: 24px;
    margin-right: 14px;
}