.top_left_pf_txt {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.profile {
    text-align: center;
    clear: both;
    transition: 0.5s;
}
.profile_pic_letter {
    display: block !important;
    margin: 0 auto;
    position: relative;
}
.profile h4 {
    font-size: 18px;
    margin-top: 20px;
    text-transform: capitalize;
}
.arrow_icon {
    margin-left: 2px;
}
.show_more.arrow_icon {
    transform: rotate(180deg);
}
.profile_tic_icon {
    position: absolute;
    right: 2px;
    top: 0;
}