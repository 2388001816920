.padding-none {
    padding: 0 !important;
}
.treatment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
    padding: 20px 20px 16px;
}
.treatment-header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}
.treatment-header>h2 {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin: 0;
}
.treatment-header-right>p {
    color: #000000;
    font-size: 12px;
    margin-bottom: 0;
}
.treatment-content {
    padding: 20px 40px 40px;
    /* border-bottom: 1px solid rgba(63, 39, 131, 0.1); */
}
.treatment-row {
    display: flex;
    margin-bottom: 16px;
}
.treatment-col {
    width: 50%;
    color: #000;
    font-size: 14px;
    padding-right: 115px;
}
.treatment-col label {
    display: block;
    margin-bottom: 7px;
    font-weight: 600;
    color: #000000;
}
.treatment-row-header {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
    text-transform: uppercase;
    padding-bottom: 13px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
}
.view__pain__diagram .pain-box {
    max-width: 500px;
    margin-left: 0;
    border: 2px solid #3f2783;
    border-radius: 20px;
    padding: 20px 20px 25px;
    margin: 0 auto;
}
.mark-row {
    display: flex;
    justify-content: space-between;
}
.view__pain__diagram .pain-box .mark-row .mark-col svg {
    max-width: 160px;
}
.mark-col p {
    text-align: center;
    color: #3f2783;
    font-size: 14px;
    font-weight: 600;
    line-height: 38px;
    margin: 15px 0 0;
}
.treatment-table {
    border-top: 1px solid rgba(63, 39, 131, 0.1);
    border-bottom: none;
    width: 100%;
    padding-bottom: 10px;
}
.treatment-border.treatment-table {
    border-bottom: none;
}
.treatment-table.border-bottom {
    border-bottom: 1px solid #e2caca00 !important;
}
.treatment-table table {
    width: 100%;
    border: none;
}
.treatment-table table th {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    width: 25%;
    padding: 20px 0 10px;
}
.treatment-table table td:first-child {
    padding-right: 10px;
}

.treatment-table table th:first-child, .treatment-table table td:first-child {
    width: 50%;
    font-weight: 600;
}
.treatment-table table td {
    color: #000;
    font-size: 14px;
    width: 25%;
    padding: 10px 0;
}
.treatment-tbl tr th:first-child, .treatment-tbl tr td:first-child {
    padding-left: 0;
}
.treatment-tbl tr th {
    font-weight: 600;
    padding: 5px 5px 18px 5px;
}
.treatment-tbl tr td {
    border-top: 1px solid rgba(63, 39, 131, 0.1);
    font-size: 14px;
    padding: 18px 5px;
}
.treatment-row-table .treatment-tbl{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
}
.treatment_subtitle {
    border-bottom: 1px solid rgba(63, 39, 131, 0.1);
    padding-bottom: 15px;
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 600;
}
.typelist-content {
    margin-top: 25px;
}
.typelist-col {
    /* margin: 20px 20px 0px 0; */
    border: 2px solid rgba(63, 39, 131, 0.7);
    border-radius: 20px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 215px;
    height: 225px;
    padding: 10px;
    position: relative;
}
.typelist-col.h_200{
    height: 200px;
}
.typelist-col.h_250{
    height: 250px;
}
.typelist-col.h_260{
    height: 260px;
}
.typelist-content span {
    display: block;
    font-weight: 600;
    color: #3f2783;
    margin-top: 15px;
}
.result-text {
    margin-bottom: 20px;
}
.treatment-col.w_100{
    width: 100%;
    padding-right: 0;
}
@media only screen and (max-width: 1199px){
    .treatment-col {
        padding-right: 30px;
    }
}
@media only screen and (max-width: 767px){
    .Rotator-tblscroll {
        width: 100%;
        overflow-x: scroll;
    }
    .treatment-row-table .Rotator-tbl {
        /* width: 766px */
    }
}
