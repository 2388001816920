.bold_ {
    color: #231e5f;
    font-weight: 700;
    font-size: 18px;
}
.para_ {
    color: #231e5f;
    font-weight: 400;
    font-size: 14px;
}
.tel_col{
    color: #50b3e5;
    font-size: 18px;
}
.f_24{
    font-size: 24px;
}
.f_20{
    font-size: 20px;
}
.f_40{
    font-size: 40px;
}
.label_col{
    color: #231e5f;
    font-weight: 400;
    font-size: 18px;
}
.doc_sign{
    border-bottom: 2px solid #50b3e5;
}
.foot_col{
    color: #28b04a;
    font-weight: 700;
}